import React from 'react'
import convert from 'xml-js'

export const Context = React.createContext({})

const Wrapper = ({ children }) => {
  const [reviewMeta, setReviewMeta] = React.useState({
    loading: true,
    average: 10,
    count: 211,
    reviews: [],
    recommendation: 99,
  })

  React.useEffect(() => {
    const get = async () => {
      try {
        const request = await fetch(
          'https://www.klantenvertellen.nl/v1/review/feed.xml?hash=mzesssmqeenx3yc'
        )

        const response = await request.text()
        const reviewData = JSON.parse(convert.xml2json(response))

        const averageRating = reviewData.elements[0].elements.filter(
          (r) => r.name === 'averageRating'
        )
        const reviewCount = reviewData.elements[0].elements.filter(
          (r) => r.name === 'numberReviews'
        )
        const reviews = reviewData.elements[0].elements
          .filter((r) => r.name === 'reviews')
          .map((review) => review.elements)

        setReviewMeta({
          average: averageRating[0].elements[0].text,
          count: reviewCount[0].elements[0].text,
          reviews: reviews[0],
          recommendation: 99,
          loading: false,
        })
      } catch (error) {
        console.error(error)
      }
    }

    get()
  }, [])

  return <Context.Provider value={{ reviewMeta }}>{children}</Context.Provider>
}

export default Wrapper
