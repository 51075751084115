import React from 'react'
import Wrapper from './src/components/layout/Wrapper'

export const onRouteUpdate = (_) => {
  if (
    process.env.NODE_ENV === `production`
  ) {
    setTimeout(() => {
      if (typeof acalltracker !== 'undefined'){
        acalltracker.dynamicPageload();
      }
    }, 50)
  }
}

export const wrapRootElement = ({ element, props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Wrapper {...props}>{element}</Wrapper>
)
